import { COLORS3, SPACING } from '@zola/zola-ui/src/styles/emotion';
import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import styled from '@emotion/styled';

export const HeaderReview = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING.XS};
  color: ${COLORS3.BLACK_030};
  font-size: ${FONTS.SIZE.SMALLER};

  ${MEDIA_QUERY_V2.MOBILE} {
    max-width: 280px;
    margin-top: ${SPACING.XS};
  }
`;

export const Bullet = styled.span`
  font-size: 6px;
`;

export const VerifiedReview = styled.span`
  display: flex;
  align-items: center;
  gap: ${SPACING.XXS};
`;
