import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import CachedImage from '~/components/common/images/CachedImage';

const PHOTO_SIZE = '96px';

export const ReviewsSectionGalleryWrapper = styled.div`
  display: flex;
  gap: ${SPACING.S16};
`;

export const PhotoPreview = styled.button<{ index: number }>`
  display: flex;
  width: fit-content;
  padding: 0;
  border: none;
  background: none;
  position: relative;
  ${MEDIA_QUERY_V2.MOBILE} {
    ${({ index }) => (index > 2 ? 'display: none' : null)}
  }
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    ${({ index }) => (index > 3 ? 'display: none' : null)}
  }
`;

export const StyledCachedImage = styled(CachedImage)`
  height: ${PHOTO_SIZE};
  width: ${PHOTO_SIZE};
  object-fit: cover;
  border-radius: ${SPACING.S16};
`;

type PhotoPreviewCountProps = { index: number; totalLength: number };

const showOnMobile = ({ index, totalLength }: PhotoPreviewCountProps) => css`
  display: none;
  ${MEDIA_QUERY_V2.MOBILE} {
    ${index === 2 && totalLength > 3 ? 'display: block' : null}
  }
`;

const showOnDesktop = ({ index, totalLength }: PhotoPreviewCountProps) => css`
  display: none;
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    ${index === 3 && totalLength > 4 ? 'display: block' : null}
  }
`;

export const PhotoPreviewOverlayWrapper = styled.div<PhotoPreviewCountProps>`
  ${(props) => showOnMobile(props)};
  ${(props) => showOnDesktop(props)};
`;

export const PhotoPreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${PHOTO_SIZE};
  width: ${PHOTO_SIZE};
  border-radius: ${SPACING.S16};
  background: ${COLORS.BLACK_100};
  opacity: 0.5;
`;

export const PhotoIconAndCount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: ${SPACING.XXS};
`;

const photoCountStyles = `
  color: ${COLORS.WHITE_100};
  font-size: ${FONTS.SIZE.SMALL};
`;

export const PhotoCountMobile = styled.span<PhotoPreviewCountProps>`
  ${photoCountStyles}
  ${(props) => showOnMobile(props)};
`;

export const PhotoCountDesktop = styled.span<PhotoPreviewCountProps>`
  ${photoCountStyles}
  ${(props) => showOnDesktop(props)};
`;
