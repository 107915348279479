import { Fragment, useCallback } from 'react';

import { PhotoIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Photo';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';

import { useLightboxContext } from '../contexts/LightboxContext';
import {
  PhotoCountMobile,
  PhotoCountDesktop,
  PhotoIconAndCount,
  PhotoPreview,
  PhotoPreviewOverlay,
  PhotoPreviewOverlayWrapper,
  ReviewsSectionGalleryWrapper,
  StyledCachedImage,
} from './ReviewsSectionGallery.styles';

type ReviewsSectionGalleryProps = {
  className?: string;
  photoUuids: string[];
};

export const ReviewsSectionGallery = ({
  className,
  photoUuids,
}: ReviewsSectionGalleryProps): JSX.Element => {
  const { openLightbox } = useLightboxContext();

  const totalLength = photoUuids.length;

  const onClickImage = useCallback(
    (uuid: string) => {
      openLightbox(uuid, 'Review photos');
    },
    [openLightbox]
  );

  if (totalLength === 0) {
    return <Fragment />;
  }

  return (
    <ReviewsSectionGalleryWrapper className={className}>
      {photoUuids.map((photoUuid, i) => {
        return (
          <PhotoPreview
            index={i}
            key={`reviews-section-gallery-${i}`}
            onClick={() => onClickImage(photoUuid)}
          >
            <StyledCachedImage uuid={photoUuid} />
            <PhotoPreviewOverlayWrapper index={i} totalLength={totalLength}>
              <PhotoPreviewOverlay />
              <PhotoIconAndCount>
                <PhotoIcon color={COLORS.WHITE_100} height={24} title="" width={24} />
                <PhotoCountMobile index={i} totalLength={totalLength}>
                  ({totalLength - 3}+)
                </PhotoCountMobile>
                <PhotoCountDesktop index={i} totalLength={totalLength}>
                  ({totalLength - 4}+)
                </PhotoCountDesktop>
              </PhotoIconAndCount>
            </PhotoPreviewOverlayWrapper>
          </PhotoPreview>
        );
      })}
    </ReviewsSectionGalleryWrapper>
  );
};
