import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';

import dynamic from 'next/dynamic';

import { LightboxEntry } from './types';

/**
 * Dynamically load either the mobile or desktop lightbox
 */
export const LightboxV2 = ({
  entries,
  initialUuid,
  onClose,
  desktopDek,
  desktopControls,
  mobileControls,
}: {
  entries: LightboxEntry[];
  initialUuid: string;
  onClose: () => void;
  desktopDek?: React.ReactNode;
  desktopControls?: React.ReactNode;
  mobileControls?: React.ReactNode;
}) => {
  if (isMobile()) {
    const MobileLightboxV2 = dynamic(() => import('./MobileLightboxV2'), {
      ssr: false,
    });
    return (
      <MobileLightboxV2
        entries={entries}
        initialUuid={initialUuid}
        onClose={onClose}
        controls={mobileControls}
      />
    );
  }
  const NotMobileLightboxV2 = dynamic(() => import('./NotMobileLightboxV2'), {
    ssr: false,
  });
  return (
    <NotMobileLightboxV2
      entries={entries}
      initialUuid={initialUuid}
      onClose={onClose}
      dek={desktopDek}
      controls={desktopControls}
    />
  );
};

export default LightboxV2;
