import { Fragment } from 'react';

import { formatDateFrom } from '@zola-helpers/client/dist/es/util/dateUtils';
import { ReviewView } from '@zola/svc-marketplace-ts-types';
import StarRatings from '@zola/zola-ui/src/components/StarRatings/StarRatings';
import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';

import { MappedReviewView } from '~/types/responseTypes';

import { HeaderReview, Bullet, VerifiedReview } from './HeaderReviewInfo.styles';

type HeaderReviewInfoProps = {
  openPhotoReview: MappedReviewView | ReviewView | null;
};

/**
 * Storefront review info on lightbox header, including rating, reviewer name, and review date
 */
export const HeaderReviewInfo = ({ openPhotoReview }: HeaderReviewInfoProps): JSX.Element => {
  const { overallRating, reviewerName, sentToBvAt } = openPhotoReview || {};
  const reviewDate = sentToBvAt ? formatDateFrom(sentToBvAt, new Date()) : null;

  if (!overallRating || !reviewerName) {
    return <Fragment />;
  }

  return (
    <HeaderReview>
      <StarRatings
        totalStars={5}
        activeStars={overallRating}
        reviewCount=""
        fillColor={COLORS.YELLOW_100}
      />
      <Bullet>&bull;</Bullet>
      <VerifiedReview>
        <CheckIcon height={20} title="" width={20} />
        <span>Verified review</span>
      </VerifiedReview>
      <Bullet>&bull;</Bullet>
      <span>{reviewerName}</span>
      {reviewDate && (
        <>
          <Bullet>&bull;</Bullet>
          <span>{reviewDate}</span>
        </>
      )}
    </HeaderReview>
  );
};
