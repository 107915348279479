import { createContext, useContext } from 'react';

import { LightboxGroup } from '~/components/common/zolaUI/Lightbox/types';

export type LightboxContextValues = {
  openLightbox: (photoUuid: string | null, initialGroup?: string) => void;
  photoGroups: LightboxGroup[];
  initialPhotoGroup: string | undefined;
};

export const LightboxContext = createContext<LightboxContextValues | undefined>(undefined);

export const useLightboxContext = () => {
  const context = useContext(LightboxContext);
  if (context === undefined) {
    throw new Error('useLightboxContext must be used within a LightboxProvider');
  }
  return context;
};
